.conteiner {
    color: white;
    width: 100%;
    
}
.wrapperTitle {
    width: 400px;
    justify-content: center;
    margin: 30px 20px 40px;
}
.listItem {
    border-bottom: 1px solid;
    text-align: left;
    margin: 40px;
    padding-bottom: 40px;
}

.selectCatalog {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.selectCatalog select {
    width: 200px;
}




.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 30px;
}


.list {
    margin-bottom: 100px;
}



.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.wrapperDel {
    display: flex;
    align-items: flex-start;


}


.wrapperTitleCatalog {
    width: 400px;
    justify-content: center;
    margin: 30px 20px 40px;
}

.wrapperTitle h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}


.close {
    position: absolute;
    color: red;
    margin-top: -20%;
    margin-left: 100%;
    cursor: pointer;

}

.uploadInput {
    width: 0;
    height: 0;
    font-size: 0;
}

.headCatalog {

    display: flex;
    justify-content: space-around;
}



.selectCatalog select {
    height: 30px;
}

.catalogInput {
    display: flex;

    justify-content: center;
}



.catalogInput input {
    height: 40px;
    margin-top: 20px;
}

