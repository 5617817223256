.conteiner {
    height: 400px;
    width: 100%;
    justify-content: center;
    display: flex;
    
}
.main {
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 300px;
    display: flex;
    color: #2c2c2c;
    flex-direction: column;
    background-color: #2e5775;
}
.main input  {
   height: 32px;
   width: 200px;
   margin-top: 20px;
}
.main button {
    height: 32px;
    width: 100px;
    margin-top: 20px;
}
.enter {
    background-color: red;
    color: white;
    font-size: medium;
}
.main h2 {
    color: white;
}
.close {
    margin-top: 1%;
    cursor: pointer;
    margin-left: 17%;
    width: 20px;
    height: 20px;
    background-color: red;
    position: absolute;
}