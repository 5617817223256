.conteiner {
    color: white;

}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 30px;
}


.list {
    margin-bottom: 100px;
}

.listItem {
    border-bottom: 1px solid;
    text-align: left;
    margin: 40px;
    padding-bottom: 40px;
}

.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.wrapperDel {
    display: flex;
    align-items: flex-start;


}

.wrapperTitle {
    margin: 30px 40px 100px;
}
.wrapperTitleCatalog {
    width: 400px;
    justify-content: center;
    margin: 30px 20px 40px;
}

.wrapperTitle h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}


.close {
    position: absolute;
    color: red;
    margin-top: -20%;
    margin-left: 100%;
    cursor: pointer;

}

.uploadInput {
    width: 0;
    height: 0;
    font-size: 0;
}

.headCatalog {

    display: flex;
    justify-content: space-around;
}

.selectCatalog {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.selectCatalog select {
    height: 30px;
}

.catalogInput {
    display: flex;

    justify-content: center;
}



.catalogInput input {
    height: 40px;
    margin-top: 20px;
}



/*underCatalog*/


.conteinerUnder {
    width: 40%;
    display: flex;
    justify-content: space-between;
    margin-top: -20px;

}

.wrapperFlex {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.wrapperDel1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid;
}

.wrapperDel2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.popUp {
    position: absolute;
    background: white;
    width: 200px;
    height: 60px;
    color: black;
}



/*subCatalog*/

.conteinerSub {
    width: 60%;
    min-height: 520px;
    position: absolute;
    background-color: #2c2c2c;
    margin-left: 15%;
    margin-top: 20px;
    
}

.subClose {

    color: red;

    cursor: pointer;

}

.btn {

    height: 50px;
    width: 150px;
    margin-top: 20px;
    background-color: red;
    color: white;
    font-size: 18px;
    text-transform: uppercase;

}