.conteiner {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}   
.conteiner  h2,h3,h4 {
    max-width: 70%;
    overflow: hidden;
}

.conteinerEdit {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pullUpDel {
    position: absolute;
    background-color: #2e5775;
    width: 200px;
    height: 140px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 20px;
}

.icons {

    cursor: pointer;
    margin-left: 40px;
 

}

input {
    height: 25px;
}

.buttons {
    display: flex;
    justify-content: space-around;
   
}

.buttons button {
    width: 32%;
    height: 34px;
    font-size: 20px;
    cursor: pointer;
    
}

.catalogInput {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.catalogInput1 {
    width: 100%;
    display: flex;
    align-items: center;

}

.catalogInput2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}


.conteinerInputC {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;

}