.conteiner {
    margin: 0;
    padding: 0;
    height: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    
}
.main {
    justify-content: left;
   
    width: 100%;
    display: flex;
    color: #2c2c2c;
    
    background-color: #2e5775;
}

.main button {
    height: 50px;
    width: 100%;
    margin-top: 20px;
    background-color: red;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    border: none;
    cursor: p;
}

.buttons {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    
    
}
.content{
    width: 85%;;
    background-color: #2c2c2c;
    border: 2px solid;
}
.content h2, h3, span{
   color: white;
}
