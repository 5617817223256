.conteiner {
    position: absolute;
    width: 1200px;
    height: auto;
    z-index: 1;
    display: flex;
    background-color: #2c2c2c;
}
.list {
    width: 15%;
}
.picBig {
    margin-left: 40px;
    width: 300px;
    height: 300px;
}
.picBig img {
    width: 100%;
    height: 100%;
}