
/*subCatalog*/

.conteinerSub {
    width: 80%;
    min-height: 800px;
    position: absolute;
    background-color: #2c2c2c;
    margin-left: 0%;
    display: flex;
   
}
.conteinerSub h2 {
    margin-bottom: 40px;
}
.menu {
    
    width: 30%;
}
.catalog {
    
    width: 30%;
    margin-left: 10px;
}
.products {
    
    max-width: 40%;
    margin-left: 40px;
}
.subClose    {
   
color: red;
position: absolute;
cursor: pointer;
margin-left: 95%;
margin-top: 0%;

}
.selector {
    display: flex;
    flex-direction: column;
}

.flexWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
}

.wrapperFlexItem {
    width: 100%;
}